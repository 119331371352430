import React from 'react'
import { graphql } from 'gatsby';
import Layout from '@components/layout'
import CaseStudiesMore from '@components/case-studies-more'
import FacebookShare from '@components/fb-share-button';
import TwitterShare from '@components/tw-share-button';

export default function crazyEgg(props) {
  const { siteUrl } = props.data.site.siteMetadata
  const thumbnail = '/images/crazyegg-case1.jpg'
  const thumbAltText = 'crazyegg case study'
  const description = 'Crazy Egg is the Leader in Visual Website Analytics and Heatmaps. WyeWorks helped scale Crazy Egg engineer team and ramp up other engineers. We worked as part of the core team maintain the product and growing it. Crazy Egg went from having one feature to having multiple features.'
  const shareUrl = siteUrl + props.location.pathname
  const caseTitle = 'WyeWorks helped Crazy Egg with core features for scaling their business.'

  return (
    <Layout
      location={props.location}
      title='Crazy Egg'
      namespace='page'
      description={description}
      thumbnail={thumbnail}
    >
      <div className='hero hero-2'>
        <div className='hero-2__background'></div>
        <div className='hero-2__container'>
          <div className='case-hero__wrapper'>
            <div className='case-hero__pattern'>
              <img src='/images/case-hero-pattern.png' alt='' />
            </div>
            <div className='content-block-1__title-label'>
              <h2 className='h2-label'>
                <div className='h2-label__title'>
                  Case Study
                </div>
                <div className='h2-label__seperator'></div>
              </h2>
            </div>
            <div className='case-hero__hero-block'>
              <div className='case-hero__hero-block-wrapper'>
                <div className='case-hero__case-logo'>
                  <img src='/images/brands/crazyegg-white-brand.png' alt='' />
                </div>
                <h3 className='case-hero__case-title'>
                  {caseTitle}
                </h3>
                <p className='case-hero__description'>
                  {description}
                </p>
              </div>
              <div className='case-hero__hero-block-background'>
                <div className='case-hero__hero-block-gradient'></div>
                <img src='/images/crazyegg-case-card-tall-bg.jpg' alt='' />
              </div>
            </div>
            <div className='case-hero__case-meta'>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Services Used
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Backend Development
                </li>
                <li className='case-meta__list-item'>
                  Frontend Development
                </li>
                <li className='case-meta__list-item'>
                  Process Consulting
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Industry
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Visual Website Analytics
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Timeline
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  4.5 years
                </li>
              </ul>
              <div className='social-layout-1'>
                <FacebookShare url={shareUrl} />
                <TwitterShare url={shareUrl} text={caseTitle.replace('WyeWorks', '@WyeWorks')}/>
                <div className='social-layout-1__seperator'></div>
                Share This Case
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='long-content__wrapper'>
          <p>
            For over 14 years companies like Etsy, ZenDesk, Dell and hundreds of thousands more have used Crazy Egg to reduce shopping cart abandonment, maximize registrations and increase subscriptions on their websites. Their marketing tools help you build a better UI experience to boost your bottom line.
          </p>
        </div>
      </section>
      <section className='section--full-right'>
        <div className='image-90__wrapper'>
          <img src={thumbnail} alt={thumbAltText} />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The solution
          </h3>
          <p>
            Over the years, WyeWorks worked hard to find a development process that evolved accordingly along with the client’s needs, prioritizing and discovering new features and building a close relationships with the leadership.
          </p>
          <p>
            We started building features on top of “Snapshots” which are visual reports that show where people are coming from, what they are seeing and using, where they are getting stuck and how to fix it, making it easier to manage all the snapshots using a dashboard. We smoothly deployed a re-architecture and upgrade of the code to support this brand new UI using Ember
          </p>
          <div className='quote-block'>
            <div className='quote-block__primary-offset'></div>
            <div className='quote-block__quote-wrapper'>
              <div className='quote-block__quote'>
                "We’ve had a lot of experience working with contractors and development shops from all over the world. WyeWorks excels in the areas that many other companies lack: communication and planning. We get all the benefits of outsourcing without any of the drawbacks."
              </div>
              <div className='quote-block__provider-title'>
                John Butler
                <br />
                Partner
              </div>
            </div>
          </div>
          <p>
            As a part of the core team we helped build the other two big features of Crazy Egg:
          </p>
          <ul className='long-content__list'>
            <li className='long-content__list-item'>
              Recordings: lets you dive deep into individual user behavior experiences by anonymously tracking visitors as they work their way through your site
            </li>
            <li className='long-content__list-item'>
              Editor: let you ab test ideas and make changes directly to your website, no designers no engineers, just you, your ideas and instant gratification
            </li>
          </ul>
        </div>
      </section>
      <section className='section--full-left'>
        <div className='image-90__wrapper'>
          <img src='/images/crazyegg-case2.jpg' alt='' />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The results
          </h3>
          <p>
            WyeWorks helped scale Crazy Egg engineer team and ramp up other engineers. We worked as part of the core team maintain the product and growing it. Crazy Egg went from having one feature to having multiple features.
          </p>
          <p>
            In the last year of the project we focused on increasing the conversion of trial members to customers, increasing the performance of Snapshots, building integrations with the top CMS in the market, adding automatic suggestions so that the customers could make changes to their sites and some other improvements on the Recordings dashboard.
          </p>
        </div>
      </section>

      <CaseStudiesMore cases={['codeclimate', 'prisma']} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
